.whiteband {
  background: var(--white);
}

.title {
  color: var(--text-color-dark);
  max-width: 425px;
  font-size: 3rem;
}

.lead {
  color: var(--color-primary-accent);
  font-weight: bold;
}

.orange {
  color: var(--color-tertiary-accent);
}

.editors {
  width: 150%;
  max-width: 850px;
  transform: translateX(-25%);
}

@media (max-width: 991.98px) and (min-width: 768px) {
  .editors {
    width: 250%;
    transform: translateX(-20%) translateY(5%);
  }
}

@media (max-width: 767.98px) {
  .editors {
    transform: translateX(-23%);
  }
}

.dog {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateY(60%);
}

@media (max-width: 767.98px) {
  .dog {
    transform: scale(.8) translateY(75%);
  }
}

.features img {
  height: 70px;
}

@media (max-width: 575.98px) {
  .feature {
    text-align: center;
    max-width: 300px;
    margin: 30px auto;
  }
}

.reason__icon {
  width: 100px;
  height: 100px;
}

.reason__title {
  font-size: 1.65rem;
}
