.card {
  background: var(--white);
  border: 1px solid rgba(0,0,0,.05);
  border-radius: 12px;
  box-shadow: 0 7px 0 0 rgba(0,0,0,.1);
  display: inline-block;
}

.thumbnail {
  background: rgba(0,0,0,.1);
  border: 1px solid rgba(0,0,0,.1);
  color: #bbbbbb;
  font-size: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.thumbnail__image {
  background: #ffffff;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
}

.thumbnail .shadow,
.thumbnail .use {
  transition: all .2s;
}

.thumbnail .shadow {
  opacity: 0;
}

.thumbnail:hover .shadow {
  opacity: 1;
}

.thumbnail .use {
  opacity: 0;
  transform: translateX(-50%) translateY(-30%);
}

.thumbnail:hover .use {
  opacity: 1;
  transform: translateX(-50%) translateY(-50%);
}

.shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.45);
}

.use {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 1rem;
  width: 70%;
}

.use button {
  width: 100%;
  min-width: 0;
}

.label {
  color: var(--text-color-dark);
}

.date {
  color: var(--text-color-dark);
  display: block;
}

.button {
  color: var(--text-color-light);
  border: 0;
  padding: 5px 15px;
  margin: 2px;
}

.button svg {
  transform: translateX(-1px) translateY(-2px);
  margin-right: 2px;
}

.edit {
  background: #30CC71;
}

.copy {
  background: #FFB468;
}

.delete {
  background: #E74D3D;
}

.download {
  text-decoration: none;
  display: flex;
  width: 100%;
}

.download:hover {
  text-decoration: none;
}

.download button {
  background: #56C1FF;
  width: 100%;
}

.small {
  width: 185px;
  height: 265px;
}

.large {
  width: 222px;
  height: 318px;
}
