.layout {
  position: relative;
}

.withDelete {
  padding-right: 30px;
}

.delete {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 5px;

  background: none;
  border: none;
}
