.link {
  color: var(--text-color-light);
  text-decoration: none;
}

.text {
  color: var(--text-color-light);
  text-align: center;
}

.text:hover {
  color: var(--text-color-light);
}

.button {
  padding-left: 0;
  padding-right: 0;
  margin-left: .5rem;
  margin-right: .5rem;
}

.dropdownText {
  color: var(--text-color-dark);
}