.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: var(--dark);
  color: var(--text-color-light);
  padding: 40px;
  text-align: center;
  overflow-y: auto;
}

.back {
  position: fixed;
  min-width: 80px;
  z-index: 999;
  bottom: 38px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-box-shadow: 0px 9px 23px -12px rgba(0,0,0,0.9);
  -moz-box-shadow: 0px 9px 23px -12px rgba(0,0,0,0.9);
  box-shadow: 0px 9px 23px -12px rgba(0,0,0,0.9);
}
