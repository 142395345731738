.section {
  background: var(--white);
  margin: 20px 0;
  border-radius: 5px;
}

.section .section {
  background: var(--light);
}

.section .section:first-child {
  margin-top: 0;
}

.title {
  font-weight: bold;
  font-size: 1.2rem;
}

.actions {
  font-size: 1.2rem;
}

@media (max-width: 575.98px) {
  .actions.bar {
    width: 100%;
    padding: 0 0 7px;
    border-bottom: 1px solid rgba(0,0,0,.1);
  }

  .actions.bar:not(.bar__expanded) {
    border-bottom: 0;
    padding-bottom: 0;
  }
}

.actions button {
  background: none;
  border: none;
}

.actions .bulb {
  width: 25px;
  height: 25px;
  transform: translateY(-3px);
  margin-left: -4px;
  margin-right: -4px;
}

.divider {
  width: 1px;
  height: 70%;
  background: rgba(0,0,0,.1);
  margin: 0 15px;
  display: inline-block;
  transform: translateY(5px);
}

@media (max-width: 575.98px) {
  .divider:first-child {
    display: none;
  }
}
