.PreviewDebug {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: calc(100% - 66px);

  overflow-y: scroll;
}

.PreviewDebugActionBar {
  position: absolute;
  z-index: 1000;
  right: 16px;
  box-shadow: 0px 0px 24px rgba(0,0,0,.5);
  transform: rotate(-90deg);
  transform-origin: right bottom;transform: rotate(-90deg);
  background: var(--dark);
}

.PreviewDebug .PreviewFrame {
  padding: 0;
}

.PreviewDebug pre {
  margin-bottom: 0;
}
