.frame a {
  color: inherit;
}

.logo {
  height: 60px;
}

.escape {
  color: var(--text-color-dark);
}

.escape:hover {
  color: var(--text-color-dark);
}
