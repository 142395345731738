.title {
  font-size: 1.2rem;
}

.title__bulb {
  width: 32px;
  height: 32px;
  transform: translateY(-2px);
}

.tips {
  list-style-type: none;
}

.tips li {
  position: relative;
}

.tips li:before {
  display: block;
  content: '';
  width: 4px;
  height: 4px;
  border-radius: 10px;
  background: var(--text-color-dark);
  opacity: .8;

  position: absolute;
  left: -30px;
  top: .65em;
}

.dismiss {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.3rem;
  padding: 23px 35px 20px 20px;
  opacity: .7;
}
