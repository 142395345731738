.title {
  font-weight: bold;
}

.psst {
  opacity: .5;
}

.pointer {
  cursor: pointer;
}

.color__tertiary {
  color: var(--color-tertiary-accent);
}
