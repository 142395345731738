.item {
  position: relative;
  list-style-type: none;
}

.check {
  position: absolute;
  left: 0;
  padding-right: .8em;
  transform: translateX(-100%) translateY(-2px);
  color: var(--color-primary-accent);
}

.check path {
  stroke-width: 3px;
  stroke: var(--color-primary-accent);
}
