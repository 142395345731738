.profile {
  margin-left: .5em;
  padding-left: .5em;
  border-left: 1px solid rgba(255,255,255,.5);
}

.profile button {
  color: var(--text-color-light);
  text-align: center;
  background: none;
  border: 0;
}

.profile button:after {
  margin-left: .5em;
}

.profile:hover button {
  color: var(--text-color-light);
  background: none;
}

.profile a {
  text-decoration: none;
}

@media (max-width: 991.98px) {
  .nav {
    width: 100%;
    flex-direction: column;
    font-size: 1.2rem;
  }

  .profile {
    margin: 0;
    padding: 0;
    margin-top: 20px;
    padding-top: 20px;
    border-left: none;
    position: relative;
  }

  .profile:before {
    content: '';
    width: 50px;
    height: 1px;
    background: var(--text-color-light);
    opacity: .3;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: block;
  }

  .profile button {
    display: block;
    width: 100%;
    pointer-events: none;
    font-size: inherit;
    opacity: .5;
  }

  .profile button:after {
    display: none;
  }

  .dropdown {
    display: block;
    left: 50% !important;
    transform: translate3d(-50%, 0, 0px) !important;
    color: inherit;
    background: inherit;
    font-size: inherit;
    border: none;
    position: relative !important;
  }

  .dropdown a {
    color: inherit;
    text-align: center;
  }

  .divider {
    margin: 20px auto;
    width: 50px;
    height: 1px;
    background: var(--text-color-light);
    opacity: .3;
  }
}
