.button {
  background: none;
  border: none;
  width: 100%;

  font-weight: bold;
  padding: .5em;

  transition: all .2s;
}

.button,
.button:hover {
  color: var(--primary);
}
