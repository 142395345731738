.lead {
  font-weight: normal;
}

.mt--1,
.my--1 {
  margin-top: -.25rem !important;
}

.mb--1,
.my--1 {
  margin-bottom: -.25rem !important;
}

.mt--2,
.my--2 {
  margin-top: -.5rem !important;
}

.mb--2,
.my--2 {
  margin-bottom: -.5rem !important;
}

.mt--3,
.my--3 {
  margin-top: -1rem !important;
}

.mb--3,
.my--3 {
  margin-bottom: -1rem !important;
}

.ml--3,
.mx--3 {
  margin-left: -1rem !important;
}

.mr--3,
.mx--3 {
  margin-right: -1rem !important;
}

.popover {
  max-width: 400px;
}

.modal-header.no-title {
  border-bottom: 0;
  padding-bottom: 0;
  height: 0;
  position: relative;
  z-index: 1;
}

.text-black-30 {
  color: rgba(0, 0, 0, 0.3) !important;
}
