.builder {
  min-height: 100vh;
  min-height: calc(100vh - var(--app-header-height));
}

.previewContainer {
  background: var(--dark);
}

@media (max-width: 1199.98px) {
  .builder {
    display: block !important;
  }

  .builder > div {
    width: 100% !important;
  }

  .editorContainer {
    padding-bottom: 100px;
  }

  .previewContainer {
    top: 0;
    left: 0;
    z-index: 99;
    position: fixed;
    height: 100%;
  }

  .previewContainer__closed {
    top: unset;
    bottom: -100%;
    transform: translateY(-100px);
    background: none;
  }
}
