.button {
  width: 100%;
  text-align: left;
}

.button:after {
  float: right;
  margin-top: 0.55em;
}

.options {
  width: 100%;
}

.option {
  width: 100%;
  padding: 0.25rem 0.75rem;
}
