.option {
  display: inline-block;
  background: var(--text-color-light);
  border: 3px solid var(--text-color-light);
  width: 150px;
  height: 150px;
  border-radius: 20px;
  text-align: center;
  transition: all .2s;
  cursor: pointer;
  border: 2px solid var(--color-primary-accent);
}

@media (max-width: 767.98px) {
  .option {
    width: auto;
    height: 70px;
    display: flex;
    flex-direction: row !important;
    margin-right: 15px !important;
    margin-bottom: 15px !important;
  }

  .option:nth-child(2) img {
    transform: none !important;
  }
}

.option:hover {
  transform: scale(1.1);
}

.option img {
  width: 70%;
  height: 55%;
}

@media (max-width: 767.98px) {
  .option img {
    width: 40px;
    height: 40px;
  }
}

.option__label {
  line-height: 1.25em;
}

@media (max-width: 767.98px) {
  .option__label {
    margin: 0 !important;
    margin-left: 10px !important;
  }
}

.active {
  border: 2px solid var(--color-tertiary-accent);
}
