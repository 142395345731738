.container {
  position: fixed;
  height: 100%;
  width: 50%;
  right: 0;
  top: 0;

  padding-top: 66px; /* App header height */
}

@media (max-width: 1199.98px) {
  .container {
    width: 100%;
  }

  .container__closed {
    padding-top: 0;
  }
}

.pager {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
}
