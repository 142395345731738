:root {
  --color-primary-accent: #569190;
  --color-secondary-accent: #F8FAFC;
  --color-tertiary-accent: #F8BB00;

  --button-color-primary: var(--color-tertiary-accent);
  --button-color-secondary: var(--color-primary-accent);

  --text-color-light: #FEFEFE;
  --text-color-dark: var(--dark);
  --text-color-heavy: #2B9F48;

  --background-color: var(--color-secondary-accent);

  --app-header-height: 66px;
}

.App {
  min-height: 100vh;
  background-color: var(--background-color);
}

a {
  color: var(--text-color-dark);
  text-decoration: underline;
  transition: all .2s;
}

a:hover {
  color: var(--text-color-heavy);
  text-decoration: underline;
}
