.container {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 3rem;
}

@media (max-width: 575.98px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 1199.98px) {
  .container:not(.container__open) {
    padding: 0;
    padding-top: 15px;
  }

  .container:not(.container__open) > * {
    display: none;
  }

  .container:not(.container__open) > *:last-child {
    display: block;
  }

  .container:not(.container__open) > *:last-child > *:first-child {
    display: none;
  }
}

.saving {
  position: absolute;
  top: 3rem;
  left: 3rem;
  transform: translateY(-115%);
  opacity: 0;
  transition: all .3s;
}

@media (max-width: 575.98px) {
  .saving {
    left: 20px;
  }
}

.saving_active {
  position: absolute;
  top: 3rem;
  left: 3rem;
  transform: translateY(-115%);
  opacity: .5;
  transition: all .3s;
}

@media (max-width: 575.98px) {
  .saving_active {
    left: 20px;
  }
}

.saving__text {
  color: var(--light);
  font-size: .9rem;
  font-weight: bold;

  display: inline-block;
  margin-left: .35rem;
  position: relative;
}

.pager {
  position: absolute;
  bottom: 3.2rem;
  left: 50%;
  transform: translateX(-50%) translateY(-90%);
  opacity: 0;
  transition: all .3s;
}

.pager_active {
  position: absolute;
  bottom: 3.2rem;
  left: 50%;
  transform: translateX(-50%) translateY(-115%);
  opacity: 1;
  transition: all .3s;
}

.pager__page_numbers {
  color: var(--light);
  font-size: .9rem;
}

.pager__button {
  border: 0;
  background: none;
  color: var(--light);
}

.document {
  border-radius: 4px;
  overflow: hidden;
  display: inline-block;

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.document__copy {
  z-index: 1;
}

.document_container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.document_container__viewport {
  display: flex;
  align-self: center;
  flex: 1;
  text-align: center;
  padding-bottom: .75rem;
}

.document_container__toolbar {
  position: relative;
  z-index: 1;
  align-self: flex-end;
  transform: translateY(10px);
  display: flex;
  width: 100%;
}

@media (max-width: 1199.98px) {
  .document_container__toolbar {
    justify-content: center;
  }
}

.document_container__toolbar > div:first-child {
  flex: 1;
}

.document_container__toolbar > div:first-child > * {
  display: none;
}

@media (max-width: 1199.98px) {
  .document_container__toolbar > div:first-child > * {
    display: block;
  }
}

.previewToggle {
  display: none;
}

@media (max-width: 1199.98px) {
  .clickCatcher {
    width: 100%;
    height: 100px;
    position: fixed;
    left: 0;
    top: 0;
    text-align: center;
  }

  .previewButton {
    margin: 0 auto;
    -webkit-box-shadow: 0px 9px 23px -12px rgba(0,0,0,0.9);
    -moz-box-shadow: 0px 9px 23px -12px rgba(0,0,0,0.9);
    box-shadow: 0px 9px 23px -12px rgba(0,0,0,0.9);
  }

  .previewButton svg {
    transform: scale(1.2) translateY(-2px);
    margin-right: 2px;
  }

  .previewToggle {
    display: inline-block;
  }
  
  .previewClosed {
    display: none;
  }
}

.dog {
  width: 30%;
}
