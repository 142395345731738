.step {
  width: 124px;
}

.circle {
  position: relative;
}

.circle:after {
  content: '';
  display: block;

  width: 110px;
  height: 1px;
  border: 1px dashed #FFB468;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(23px);
}

.step:last-child .circle:after {
  display: none;
}

.circle img {
  width: 40px;
  height: 40px;
}
