.bar {
  position: fixed;
  z-index: 999;
  height: var(--app-header-height);

  background-color: var(--color-primary-accent);
  color: var(--text-color-light);
}

@media (max-width: 991.98px) {
  .bar {
    max-width: none;
  }
}

.bar__sectioner {
  display: flex;
}

.logo__container {
  width: 60px;
  height: var(--app-header-height);
  position: fixed;
  top: 0;
  padding-top: 10px;
  overflow: hidden;
}

.logo {
  width: 100%;
  height: auto;
}

.title {
  font-weight: bold;
  font-size: 1.8rem;
  color: var(--text-color-light);
  margin-top: 14px;
}

@media (max-width: 575.98px) {
  .title {
    font-size: 1.25rem;
    margin-top: 20px;
  }
}

.titleLink {
  text-decoration: none;
}

.titleLink:hover {
  text-decoration: none;
}

.title__text {
  margin-left: 70px;
}

.links__open,
.links__close {
  display: none;
  font-size: 35px;
  padding: 5px 15px;
  position: absolute;
  right: 0;
  top: 0;
}

.links__close {
  transform: scale(1.2) translateY(-2px);
}

@media (max-width: 991.98px) {
  .links__open,
  .links__close {
    display: block;
  }
}

.links {
  flex: 1;
  text-align: right;
  align-self: center;
  height: var(--app-header-height);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media (max-width: 991.98px) {
  .links {
    position: fixed;
    background: var(--dark);
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(.8);
    opacity: .3;
  }

  .links__visible {
    top: 0;
    left: 0;
    transform: scale(1);
    opacity: 1;
    transition: opacity .1s ease-out, transform .1s ease-out;
  }
}

.links ul {
  align-items: center;
}

.spacer {
  position: relative;
  height: var(--app-header-height);
}

.spacer::after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--app-header-height);
  background-color: var(--color-primary-accent);
}

.super {
  display: inline-block;
  text-transform: uppercase;
  font-weight: normal;
  font-size: .8rem;
  transform: translateX(1px) translateY(-70%);
}

@media (max-width: 575.98px) {
  .super {
    font-size: .6rem;
    margin-top: 12px;
  }
}
