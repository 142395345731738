.title {
  position: relative;
  font-size: 2rem;
  margin: 0;
  display: inline-block;
  cursor: pointer;
}

.icon {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: -.1em;

  width: 18px;
  height: 18px;
}

.title .icon {
  transform: translateX(100%) translateY(-50%);
}

.saved {
  display: block;
}
