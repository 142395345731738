.login {
  margin-right: 100px;
}

.forgot {
  display: inline-block;
  font-size: .9rem;
  opacity: .5;

  color: inherit;
  text-decoration: none;
}

.forgot:hover {
  color: inherit;
  text-decoration: none;
}