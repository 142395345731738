.button {
  font-weight: bold;
  text-align: center;
  border: 0;
  border-radius: 50px;
  padding: 10px 30px;
  min-width: 180px;
  transition: all .2s;
}

.button:disabled {
  background: #ccc;
}

.ctaPrimary {
  color: var(--text-color-light);
  background: var(--button-color-primary);
}

.ctaSecondary {
  color: var(--text-color-light);
  background: var(--button-color-secondary);
}

.ctaPrimaryInverse {
  color: var(--button-color-primary);
  background: white;
}

.ctaSecondaryInverse {
  color: var(--button-color-secondary);
  background: white;
}

.danger {
  border-radius: 0;
  background: var(--red);
  color: var(--text-color-light);
}

.neutral {
  border-radius: 0;
}
