.footer {
  background: var(--color-primary-accent);
  color: var(--text-color-light);
}

.footer ul,
.footer li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.footer a,
.footer a:hover {
  color: var(--text-color-light);
  text-decoration: none;
}

.social li {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}

.social span {
  display: inline-block;
  width: 45px;
  height: 45px;
  border-radius: 50px;
  color: rgba(0,0,0,0);
}

.social svg {
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  left: 50%;
  top: 50%;

  width: 45%;
  height: 45%;
}

.facebook {
  background: #3C5A98;
}

.facebook svg {
  margin-left: -1px;
}

.youtube {
  background: #CA3737;
}
