.hero {
  width: 100%;
}

.mobiles {
  width: 80%;
}

.body a {
  color: inherit;
}

.title {
  font-weight: bold;
  font-size: 1.2em;
}

.disclaimer {
  display: block;
  font-size: 70%;
  line-height: 1.4;
}

.escape {
  color: var(--text-color-dark);
}

.escape:hover {
  color: var(--text-color-dark);
}
